@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "./apply/navBtn.css";
@import "./apply/nestedBtn.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    body {
        font-family: 'Inter', sans-serif;
    }

    .simple {
        background-color: #E17654;
    }

    .rugged {
        background-color: #115E59;
    }

    .luxury {
        background-color: #161616;
    }
}